import { useEffect } from 'react'
import { useQuery } from 'react-query'
import queryString from 'query-string'
import { useSession } from '@cpms/common/context/AuthProvider'
import { getAuthorisation } from '@cpms/common/requests/Authorisation'
import { queryOptions } from '@cpms/common/config/settings'
import { QueryKeys } from '@app/models/QueryKeys.enum'

/**
 * Hook to authorise a user for a particular study and retrieve + save their StudyRole(s)
 */
function useStudyAuthorisation() {
  const [session, setSession] = useSession()
  const { studyId } = queryString.parse(window.location.search)

  const { data: userData } = useQuery(
    [QueryKeys.GetStudyAuthorisation, studyId],
    () => getAuthorisation(session.user.UserId, Number(studyId)),
    {
      ...queryOptions,
      enabled: !!studyId && !!session.user.UserId,
    },
  )

  useEffect(() => {
    if (userData) {
      setSession({ ...session, user: userData })
      localStorage.setItem('session', JSON.stringify(session))
    }
  }, [userData])
}

export default useStudyAuthorisation
