import { Checkboxes } from 'nhsuk-react-components'
import { ReactNode } from 'react'
import cx from 'classnames'

export interface CheckboxesProps {
  label: ReactNode
  error?: string | boolean
  hint?: string
  id: string
  name: string
  'data-testid'?: string
  checked?: boolean
}

export const Checkbox = ({ variant, ...rest }: React.HTMLProps<HTMLInputElement> & { variant?: 'secondary' }) => {
  return (
    <Checkboxes.Box
      {...rest}
      labelProps={{
        className: cx({ 'nhsuk-checkboxes__label--secondary': variant === 'secondary' }),
      }}
    ></Checkboxes.Box>
  )
}

const CustomCheckboxes = ({
  children,
  'data-testid': dataTest = 'checkboxes',
  label = undefined,
  ...rest
}: { children: React.ReactNode } & CheckboxesProps) => (
  <>
    {label}
    <Checkboxes data-testid={dataTest} {...rest}>
      {children}
    </Checkboxes>
  </>
)

export default CustomCheckboxes
