type TickIconProps = { height?: number; width?: number; className?: string }

const TickIcon = ({ height = 35, width = 40, className = 'fill-current', ...props }: TickIconProps) => (
  <svg className={className} xmlns='http://www.w3.org/2000/svg' style={{ width, height }} aria-hidden {...props}>
    <path
      fill='#027F3A'
      fillRule='evenodd'
      d='M0 16.862l7.383-.095a27.239 27.239 0 017.6 6.332C20.668 13.868 27.198 6.304 34.376 0H40C29.964 10.922 21.774 22.615 15.225 35 11.722 27.643 6.823 21.45 0 16.862z'
    ></path>
  </svg>
)

export default TickIcon
