import React, { lazy, Suspense, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { BrowserRouter, BrowserRouter as Router, Redirect, Route, Switch, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import Header from '@cpms/common/components/Header/Header'
import { Layout, Container } from '@cpms/common/components/Layout'
import Error from '@cpms/common/components/Error'
import Card from '@cpms/common/components/Card'
import Loader from '@cpms/common/components/Loader'
import { getLogoutUrl, useSession } from '@cpms/common/context/AuthProvider'
import { ErrorSummaryProvider, useErrorSummary } from '@cpms/common/context/ErrorSummaryContext'
import Footer from '@cpms/common/components/Footer/index'
import { ErrorMessageWithFocus } from '@cpms/common/components/ErrorMessage'
import Banner from '@cpms/common/components/Banner/Banner'
import AuthService from '@cpms/common/services/AuthService'

import useStudyAuthorisation from '@app/hooks/queries/useStudyAuthorisation'
import SoecatWorkflow from '@app/pages/SoecatWorkflow'
import { environmentVariables } from './environment-variables'
import settings from './config/settings'

import './styles/app.scss'

const CompleteSoecat = lazy(() => import('@app/pages/CompleteSoecat/CompleteSoecat'))
const MySoecats = lazy(() => import('@app/pages/MySoecats/MySoecats'))

const ErrorMessageWrapper = () => {
  const { pathname } = useLocation()
  const errorMessages = useErrorSummary()
  // Clear errors when switching routes
  useEffect(() => errorMessages.dispatch({ type: 'clear' }), [pathname])
  return <ErrorMessageWithFocus errors={errorMessages.state} />
}

const App = () => {
  const [{ user, error: authError, auth }] = useSession()

  useStudyAuthorisation()

  const permissionAccessError = authError === 'unauthenticated'

  return (
    <Layout>
      <Router>
        <Helmet title={settings.appTitle} />
        <ErrorSummaryProvider>
          <Header showNavMenu={false} />
          <Container>
            <Banner
              active={permissionAccessError}
              heading='There was a problem with your permissions'
              type='error'
              data-testid='unauthenticated-banner'
              role='alert'>
              If you think you have the correct permissions and the issue persists, attempt a{' '}
              <a
                href={getLogoutUrl(auth.accessToken)}
                onClick={(evt: React.MouseEvent) => {
                  evt.preventDefault()
                  const target = evt.target as HTMLAnchorElement
                  AuthService.logout(target.href)
                }}>
                logout
              </a>{' '}
              or{' '}
              <a href='https://portal.nihr.ac.uk/help?app=CPMS' rel='external'>
                contact support
              </a>
              <p>
                <a href={process.env.REACT_APP_CRN_APP_BASE_URL} rel='external'>
                  CPMS Homepage
                </a>
              </p>
            </Banner>

            {!user.UserId && (
              <Card>
                <Loader message='Logging In...'></Loader>
              </Card>
            )}

            <ErrorMessageWrapper />

            {!permissionAccessError && user.UserId && (
              <BrowserRouter>
                <Suspense
                  fallback={
                    <Card>
                      <Loader message='Loading...' />
                    </Card>
                  }>
                  <Switch>
                    <Route path='/my-soecats' exact component={MySoecats} />
                    <Route path='/error' exact component={Error} />
                    <Route path='/complete-soecat' component={CompleteSoecat} />
                    <Route path='/soecat-workflow/:soecatId' component={SoecatWorkflow} />
                    <Route
                      exact
                      path='/'
                      render={({ location }) => {
                        const { state } = queryString.parse(location.search.substring(1))
                        if (state) {
                          return <Redirect to={state as string} />
                        }
                        // Sometimes state is unavailable, e.g. after logout you are redirected
                        // to a login screen and the state param is lost. Fallback to SIM homepage
                        window.location.replace(environmentVariables.REACT_APP_SIM_APP_BASE_URL)
                        return null
                      }}
                    />
                    <Redirect to={'/error?reason=notfound'} />
                  </Switch>
                </Suspense>
              </BrowserRouter>
            )}
          </Container>
          <Footer />
        </ErrorSummaryProvider>
      </Router>
    </Layout>
  )
}

export default App
