import React, { forwardRef } from 'react'
import { Document } from '@contentful/rich-text-types'
import { useErrorSummary } from '../../context/ErrorSummaryContext'
import { ValidationError } from '../../custom'
import Banner from '../Banner/Banner'
import './error-message.scss'
import { MultipleErrorList } from './MultipleErrorList'

export interface ErrorProps {
  message?: string

  label?: string
  name?: string
  link?: React.RefObject<HTMLElement>
  multipleErrors?: ValidationError[]
  description?: Document | undefined | string

  // Deprecated
  error?: boolean
}

export const ErrorMessage = forwardRef<
  HTMLDivElement,
  { errors: ErrorProps; shouldFocus?: boolean; onFocused?: () => void }
>(({ errors, shouldFocus, onFocused }, ref) => {
  const { error = false, message, label = '', name = '', description = undefined, link, multipleErrors = [] } = errors

  const combinedErrors = multipleErrors.length !== 0 ? multipleErrors : [{ label, name, error, link, description }]

  return (
    <Banner active={error} type='error' heading={message} data-testid='ErrorMsg' innerRef={ref}>
      <MultipleErrorList errorList={combinedErrors || []} shouldFocus={shouldFocus} onFocused={onFocused} />
    </Banner>
  )
})

export const ErrorMessageWithFocus = (props: { errors: ErrorProps }) => {
  const errorMessages = useErrorSummary()
  return (
    <ErrorMessage
      {...props}
      shouldFocus={!!errorMessages.state.focus}
      onFocused={() => {
        errorMessages.dispatch({ type: 'focus', payload: false })
      }}
    />
  )
}
