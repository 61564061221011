import React, { useEffect, useRef } from 'react'
import { Document } from '@contentful/rich-text-types'
import { ValidationError } from '../../custom'
import RichText from '../RichText'

type MultipleErrorsListProps = {
  errorList: Array<ValidationError>
  shouldFocus?: boolean
  onFocused?: () => void
}

export const MultipleErrorList = ({ errorList = [], shouldFocus, onFocused }: MultipleErrorsListProps) => {
  const errorRefs = useRef<Array<HTMLAnchorElement | null>>([])

  // Backwards compatible solution for auto focusing first error link
  useEffect(() => {
    if (shouldFocus && errorRefs.current.length > 0) {
      errorRefs.current[0]?.focus()
      onFocused?.()
    }
  }, [errorRefs, shouldFocus])
  return (
    <ul className='error-list'>
      {errorList.map((error, idx) => {
        if (!error.label) return null
        const RouterLink = error.route
        return (
          <li key={error.label}>
            <>
              {/* Display a clickable link if the error contains an associated formInputId or link (ref) */}
              {error.formInputId || error.link || error.route ? (
                <>
                  {RouterLink ? (
                    <RouterLink
                      innerRef={(ref) => {
                        ref?.setAttribute('data-testid', 'errorLabel')
                        errorRefs.current[idx] = ref
                      }}
                    />
                  ) : (
                    <a
                      ref={(ref) => {
                        errorRefs.current[idx] = ref
                      }}
                      className='quotes text-base underline'
                      href={`#${error.formInputId || ''}`}
                      aria-describedby={error.formInputId}
                      onClick={(evt: React.MouseEvent) => {
                        evt.preventDefault()
                        /* Scroll to element via pass in input field id */
                        if (error.formInputId) {
                          document.getElementById(error.formInputId)?.scrollIntoView({ behavior: 'smooth' })
                          setTimeout(() => {
                            if (error.formInputId) document.getElementById(error.formInputId)?.focus()
                          }, 250)
                        }

                        /* Scroll to element via passed in ref */
                        if (error.link) {
                          error.link.current?.scrollIntoView({ behavior: 'smooth' })
                          setTimeout(() => {
                            if (error.link) error.link.current?.focus()
                          }, 250)
                        }
                      }}
                      data-testid='errorLabel'
                    >
                      {error.label}
                    </a>
                  )}
                </>
              ) : (
                // Otherwise, just display a span as it is not interactive
                <span className='quotes text-base'>{error.label}</span>
              )}
            </>

            {error.description !== undefined &&
              (typeof error.description === 'string' ? (
                <p className='error-description' data-testid='errorDescription'>
                  {error.description}
                </p>
              ) : typeof error.description !== 'string' ? (
                <RichText className='mx-4' data-testid='errorDescription' richTextDocument={error.description} />
              ) : (
                <></>
              ))}
          </li>
        )
      })}
    </ul>
  )
}
