type InfoProps = { height?: number; width?: number; className?: string }

const Info = ({ height = 32, width = 32, className = 'fill-current', ...props }: InfoProps) => (
  <svg
    className={`nhsuk-icon ${className}`}
    xmlns='http://www.w3.org/2000/svg'
    viewBox={`0 0 29 29`}
    aria-hidden
    focusable='false'
    fill='#193E72'
    style={{ width, height }}
    {...props}
  >
    <path
      fill='#193E72'
      d='M14.5 0C6.496 0 0 6.496 0 14.5S6.496 29 14.5 29 29 22.504 29 14.5 22.504 0 14.5 0zm1.45 21.75h-2.9v-8.7h2.9v8.7zm0-11.6h-2.9v-2.9h2.9v2.9z'
    ></path>
  </svg>
)

export default Info
