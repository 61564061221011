import { User } from '../models/Auth'
import { api } from '../helpers/api-axios'

export const getAuthorisation = async (userId: string, studyId: User['StudyId']) => {
  const response = await api.get<User>(
    `${process.env.REACT_APP_BASE_API_URL}/sim/v1/authorisation/${userId}?studyid=${studyId}`,
  )
  return response.data
}

export default { getAuthorisation }
