import React, { HTMLAttributes } from 'react'
import cx from 'classnames'

const Card = (props: HTMLAttributes<HTMLDivElement>) => (
  <div {...props} className={cx('bg-white p-6', props.className)}>
    {props.children}
  </div>
)

export default Card
