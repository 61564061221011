import React from 'react'
import './Button.css'
import cx from 'classnames'

export interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  type?: 'button' | 'submit' | 'reset'
  variant?: 'primary' | 'secondary' | 'info'
  size?: 'base' | 'narrow'
  text?: string
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  tabIndex?: number
  innerRef?: React.Ref<HTMLButtonElement>
  disabled?: boolean
  className?: string
}

const Button = ({
  type = 'submit',
  variant = 'primary',
  size = 'base',
  text,
  onClick,
  tabIndex,
  innerRef,
  disabled,
  className,
  ...rest
}: ButtonProps) => {
  return (
    <button
      ref={innerRef}
      type={type}
      onClick={onClick}
      disabled={disabled}
      {...rest}
      className={cx('nhsuk-button mb-0', className, {
        'nhsuk-button--secondary': variant === 'secondary',
        'nhsuk-button--reverse': variant === 'info',
        'py-2 mb-1 text-sm': size === 'narrow',
      })}
      tabIndex={tabIndex}
    >
      {text}
    </button>
  )
}

export default Button
