import { ReactNode, forwardRef } from 'react'
import cx from 'classnames'

import { InputInterface } from '../../custom'
import './RadioButton.styles.scss'
import RichText from '../RichText'

type RadioGroupProps = { children: ReactNode; id: string; label: ReactNode }

export const RadioGroup = ({ id, label, children }: RadioGroupProps) => {
  return (
    <div role='radiogroup' aria-labelledby={`${id}-label`} className='radios-container'>
      <label id={`${id}-label`}>{label}</label>
      {children}
    </div>
  )
}

const RadioButton = forwardRef<any, InputInterface & { variant?: 'secondary' }>(
  (
    {
      label,
      setValue,
      description = '',
      value,
      error,
      tabIndex = 0,
      'data-testid': dataTest,
      disabled = false,
      selected = '',
      name = 'radio',
      id,
      variant,
    },
    ref,
  ) => {
    const checked = value === selected
    return (
      <div className='nhsuk-radios__item radio-card'>
        <input
          ref={ref}
          id={id ?? `pa-${name}-${value}`}
          type='radio'
          name={`pa-${name}`}
          checked={checked}
          className={cx('nhsuk-radios__input', {
            'nhsuk-radios__input--error': error,
            'nhsuk-radios__input--secondary': variant === 'secondary',
          })}
          onChange={() => setValue && setValue(value)}
          aria-checked={checked}
          data-testid={dataTest}
          disabled={!checked && disabled}
          aria-describedby={`description-${name}-${value}`}
        />
        <label
          htmlFor={id ?? `pa-${name}-${value}`}
          className={cx('nhsuk-label nhsuk-radios__label', {
            'nhsuk-radios__label--secondary': variant === 'secondary',
          })}
          data-testid={`${dataTest}-label`}
        >
          {label}
        </label>
        {description && typeof description === 'string' ? (
          <p id={`description-${name}-${value}`} className='description' data-testid={`${dataTest}-description`}>
            {description}
          </p>
        ) : Object.keys(description).length !== 0 ? (
          <RichText
            id={`description-${value}`}
            className='description'
            data-testid={`${dataTest}-description`}
            // @ts-ignore
            richTextDocument={description}
          />
        ) : (
          <></>
        )}
      </div>
    )
  },
)

export default RadioButton
