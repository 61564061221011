import { ReactNode } from 'react'
import { Options, documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES, Document } from '@contentful/rich-text-types'
import cx from 'classnames'

import Heading, { HeadingProps } from '../Heading/Heading'
import './RichText.scss'

const OrderedList = ({ children }: { children: ReactNode }) => <ol>{children}</ol>

const options = (size?: HeadingProps['size']): Options => ({
  renderNode: {
    [BLOCKS.OL_LIST]: (node, children) => <OrderedList>{children}</OrderedList>,
    [BLOCKS.HEADING_1]: (node, children) => (
      <Heading as='h2' size={size || 'xl'}>
        {children}
      </Heading>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <Heading as='h2' size={size || 'lg'}>
        {children}
      </Heading>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <Heading as='h3' size={size || 'lg'}>
        {children}
      </Heading>
    ),
    [INLINES.HYPERLINK]: (node, children) => (
      <a
        href={node?.data?.uri}
        aria-label={`${String(children)} (opens in new window)`}
        target='_blank'
        rel='noreferrer'>
        {children}
      </a>
    ),
  },
})

interface RichTextProps {
  size?: HeadingProps['size']
  richTextDocument?: Document
  className?: string
  padding?: boolean
  'data-testid'?: string
}

const RichText = ({ richTextDocument, padding = true, className, 'data-testid': dataTest, size }: RichTextProps) => {
  if (richTextDocument !== undefined) {
    return (
      <div
        data-testid={dataTest}
        className={cx('contentful-container', className, {
          'contentful-container--padding': padding,
        })}>
        {documentToReactComponents(richTextDocument, options(size))}
      </div>
    )
  }
  return <></>
}

export default RichText
