import { lazy } from 'react'

export const IsApplyingForFunding = lazy(() => import('./ApplyingForFunding'))
export const ApplyingForPortfolioSupport = lazy(() => import('./ApplyingForPortfolioSupport'))
export const FunderList = lazy(() => import('./FunderList'))
export const HasSecuredFunding = lazy(() => import('./HasSecuredFunding'))
export const IsNhsStudy = lazy(() => import('./IsNhsStudy'))
export const Summary = lazy(() => import('./Summary'))
export const ManageSoecat = lazy(() => import('./ManageSoecat'))
export const WillStudyBeSingleSite = lazy(() => import('./WillStudyBeSingleSite'))
