import { HTMLAttributes, RefObject, MouseEvent, useRef, useState, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import { SkipLink } from 'nhsuk-react-components'
import NIHRIcon from '../Icons/NIHR'
import CogIcon from '../Icons/Cog'
import HomeIcon from '../Icons/Home'
import DropdownMenu from '../DropdownMenu'
import Navigation from '../Navigation/Navigation'
import { getLogoutUrl, useSession } from '../../context/AuthProvider'
import AuthService from '../../services/AuthService'
import { MenuItem } from '../../models/MenuResponse.interface'

import Hamburger from './Hamburger'

import './Header.styles.scss'

const styles = {
  header: 'flex items-center bg-blue text-white mb-5',
  navBtn:
    'absolute top-0 left-0 rounded-full w-12 h-12 flex items-center justify-center transition duration-200 ease-in-out motion-reduce:transition-none',
  title: 'uppercase xl inline-block text-base lg:text-lg leading-tight pl-0',
}

type HeaderProps = {
  fetchingMenu?: boolean
  menuItems?: Array<MenuItem>
  showNavMenu?: boolean
}

const HomeLink = ({
  children,
  className,
  innerRef,
  ...rest
}: HTMLAttributes<HTMLElement> & {
  children: ReactNode
  className?: string
  innerRef?: RefObject<any>
}) => {
  let pathname: string | undefined = process.env.REACT_APP_SIM_APP_BASE_URL
  const props = { ...rest, className, 'aria-label': 'Central Portfolio management system - Go to homepage' }

  // For non-sim applications, return the sim home url
  if (!process.env.REACT_APP_AUTH_ENVIRONMENT?.includes('sim')) {
    if (process.env.REACT_APP_AUTH_ENVIRONMENT === 'localsoecat')
      pathname = process.env.REACT_APP_SIM_APP_BASE_URL_LOCAL
    return (
      <a href={pathname} {...props} ref={innerRef}>
        {children}
      </a>
    )
  }

  if (process.env.REACT_APP_AUTH_ENVIRONMENT?.includes('sim')) pathname = '/'

  return (
    <Link to={pathname ?? '/'} innerRef={innerRef} aria-label={props['aria-label']} className={className}>
      {children}
    </Link>
  ) // Sim app can take advantage of react-router
}

const Header = ({ showNavMenu = true, fetchingMenu = true, menuItems = [] }: HeaderProps) => {
  const [{ user, auth }] = useSession()
  const routeChangeFocusRef = useRef<HTMLSpanElement>(null)
  const navToggleRef = useRef<HTMLButtonElement>(null)
  const titleRef = useRef<HTMLAnchorElement>(null)
  const [isMenuOpen, toggleMenu] = useState<boolean>(false)

  return (
    <>
      <header role='banner' aria-label='CPMS' data-testid='header' className={cx('header', styles.header)}>
        <div className='w-full px-4'>
          <div className='flex w-full justify-between'>
            <div className='relative flex items-center'>
              <div className='w-12'>
                <span ref={routeChangeFocusRef} tabIndex={-1} />

                {showNavMenu && (
                  <button
                    aria-label='Toggle menu'
                    aria-expanded={isMenuOpen}
                    aria-controls='cpms-nav'
                    className={cx('header-nav-btn', styles.navBtn, {
                      'header-nav-btn--active ': isMenuOpen,
                    })}
                    onClick={() => {
                      toggleMenu(!isMenuOpen)
                    }}
                    tabIndex={isMenuOpen ? 1 : 0}
                    ref={navToggleRef}>
                    <Hamburger isActive={isMenuOpen} />
                  </button>
                )}

                {!showNavMenu && (
                  <HomeLink className={cx('header-nav-btn', styles.navBtn)} aria-hidden tabIndex={-1}>
                    <HomeIcon className='fill-current text-white' />
                  </HomeLink>
                )}
              </div>
              <HomeLink
                innerRef={titleRef}
                className='header-title nhsuk-header__link nhsuk-header__link--service ml-4'
                aria-labelledby='cpms-title'>
                <h1 id='cpms-title' className={cx('nhsuk-header__service-name', styles.title)} aria-hidden>
                  Central Portfolio management system
                </h1>
              </HomeLink>
              <SkipLink id='cpms-skip-link' />
            </div>
            <div className='flex items-center'>
              <span className='hidden text-sm md:block'>{user?.Email || 'Logged Out'}</span>

              <DropdownMenu
                items={[
                  {
                    text: 'Edit Profile',
                    href: `${process.env.REACT_APP_CRN_APP_BASE_URL}/Account/ProfileEdit/${user.PersonId}`,
                    target: '_self',
                  },
                  {
                    text: 'Logout',
                    href: getLogoutUrl(auth.accessToken),
                    onClick: (evt: MouseEvent) => {
                      evt.preventDefault()
                      const target = evt.target as HTMLAnchorElement
                      AuthService.logout(target.href)
                    },
                  },
                ]}
                buttonClassName='nhsuk-button--link ml-3'
                buttonArrow={false}
                buttonText={
                  <>
                    <CogIcon aria-hidden />
                    <span className='sr-only'>Settings menu</span>
                  </>
                }
              />
              <a
                href='https://www.nihr.ac.uk'
                rel='noreferrer'
                target='_blank'
                aria-label='Go to n i h r .ac.uk (opens in new window)'
                className='ml-8'>
                <NIHRIcon width={150} aria-hidden />
              </a>
            </div>
          </div>
        </div>
      </header>
      {showNavMenu && (
        <Navigation
          fetchingMenu={fetchingMenu}
          menuItems={menuItems}
          toggleMenu={toggleMenu}
          toggleRef={navToggleRef}
          titleRef={titleRef}
          isOpen={isMenuOpen}
          routeChangeFocusRef={routeChangeFocusRef}
        />
      )}
    </>
  )
}

export default Header
