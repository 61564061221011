export const queryOptions = {
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  keepPreviousData: true,
}

const mimeTypes = {
  doc: 'application/doc, application/ms-doc, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  xls: 'application/excel, application/vnd.ms-excel, application/x-excel, application/x-msexcel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ppt: 'application/mspowerpoint, application/powerpoint, application/vnd.ms-powerpoint, application/x-mspowerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  pdf: 'application/pdf',
}

export const supportedFileUploadTypes = [
  `${mimeTypes.doc}, ${mimeTypes.xls}, ${mimeTypes.ppt}, ${mimeTypes.pdf}, image/*`,
]
