import axios, { AxiosError, AxiosInstance } from 'axios'
import log from 'loglevel'
import { HttpStatusCode } from '../models/HttpStatusCode.enum'

// eslint-disable-next-line import/no-mutable-exports
let api: AxiosInstance

export function initApi(onUnauthorizeCallback: (status: HttpStatusCode) => void) {
  if (!api) {
    api = axios.create()

    api.interceptors.request.use((req) => {
      log.debug(req)
      if (api.defaults.headers.common.Authorization) return req
      throw { message: 'the token is not available' }
    })

    api.interceptors.response.use(
      (resp) => resp,
      (error: AxiosError) => {
        if (error && error.response) {
          const { status } = error.response
          if (status === 401 || status === 403) {
            log.debug(error.response.data)
            onUnauthorizeCallback(status)
          }
        }

        return Promise.reject(error)
      },
    )
  }

  return api
}

export const setAuthToken = (apiInstance: AxiosInstance, token: string) => {
  if (token) {
    // applying token
    apiInstance.defaults.headers.common.Authorization = `${token}`
  } else {
    // deleting the token from header
    delete apiInstance.defaults.headers.common.Authorization
  }
}

export { api }
