import Screens from './screens'
import Entries from './entries'

const screenNameMapping: { [key in Screens]: { screenTagId: string; entries: Entries[] } } = {
  [Screens.GUIDANCE_SCREEN]: {
    screenTagId: 'soecatGuidanceScreen',
    entries: [Entries.GUIDANCE_SCREEN_RICH_TEXT],
  },
  [Screens.APPLYING_FOR_FUNDING_SCREEN]: {
    screenTagId: 'soecatApplyingForFundingGuidanceScreen',
    entries: [Entries.APPLYING_FOR_FUNDING_GUIDANCE_TEXT],
  },
  [Screens.WHOS_IS_THE_FUNDER_GUIDANCE_SCREEN]: {
    screenTagId: 'soecatWhoIsTheFunderGuidanceScreen',
    entries: [Entries.WHOS_IS_THE_FUNDER_GUIDANCE_TEXT],
  },
  [Screens.APPLYING_FOR_PORTFOLIO_ADOPTION_GUIDANCE_SCREEN]: {
    screenTagId: 'soecatApplyingForPortfolioAdoptionGuidanceScreen',
    entries: [Entries.APPLYING_FOR_PORTFOLIO_ADOPTION_GUIDANCE_TEXT],
  },
  [Screens.HAS_SECURED_FUNDING_GUIDANCE_SCREEN]: {
    screenTagId: 'soecatHasSecuredFundingGuidanceScreen',
    entries: [Entries.HAS_SECURED_FUNDING_GUIDANCE_TEXT],
  },
  [Screens.IS_STUDY_IN_NHS_GUIDANCE_SCREEN]: {
    screenTagId: 'soecatIsStudyInNHSGuidanceScreen',
    entries: [Entries.IS_STUDY_IN_NHS_GUIDANCE_TEXT],
  },
  [Screens.WILL_STUDY_BE_SINGLE_SITE_GUIDANCE_SCREEN]: {
    screenTagId: 'soecatWillStudyBeSingleSiteGuidanceScreen',
    entries: [Entries.WILL_STUDY_BE_SINGLE_SITE_GUIDANCE_TEXT],
  },
  [Screens.GENERAL_ACTIVITIES_GUIDANCE_SCREEN]: {
    screenTagId: 'soecatGeneralActivitiesGuidanceScreen',
    entries: [Entries.GENERAL_ACTIVITIES_GUIDANCE_TEXT],
  },
  [Screens.PERPARTICIPANT_ACTIVITIES_GUIDANCE_SCREEN]: {
    screenTagId: 'soecatPerParticipantActivitiesGuidanceScreen',
    entries: [Entries.PERPARTICIPANT_ACTIVITIES_GUIDANCE_TEXT],
  },
  [Screens.PERPARTICIPANT_ACTIVITIES_SET_GUIDANCE_SCREEN]: {
    screenTagId: 'soecatPerParticipantActivitiesSetGuidanceScreen',
    entries: [Entries.PERPARTICIPANT_ACTIVITIES_SET_GUIDANCE_TEXT],
  },
  [Screens.ADD_ACTIVITY_MODAL]: {
    screenTagId: 'soecatAddActivityModal',
    entries: [Entries.ADD_ACTIVITY_GUIDANCE_TEXT],
  },
  [Screens.STANDARD_OF_CARE_GUIDANCE_SCREEN]: {
    screenTagId: 'soecatStandardOfCareSetGuidanceScreen',
    entries: [Entries.STANDARD_OF_CARE_GUIDANCE_TEXT],
  },
  [Screens.STANDARD_OF_CARE_ADD_ACTIVITY_MODAL]: {
    screenTagId: 'soecatStandardOfCareAddActivityModal',
    entries: [Entries.STANDARD_OF_CARE_ADD_ACTIVITY_GUIDANCE_TEXT],
  },
  [Screens.REORDER_VISITS_MODAL]: {
    screenTagId: 'soecatReorderVisitsModal',
    entries: [Entries.REORDER_VISITS_GUIDANCE_TEXT],
  },
  [Screens.SUMMARY_SCREEN]: {
    screenTagId: 'soecatSummaryScreen',
    entries: [Entries.SUMMARY_GUIDANCE_TEXT],
  },
  [Screens.REQUEST_AUTHORISATION_SCREEN]: {
    screenTagId: 'soecatRequestAuthorisationScreen',
    entries: [
      Entries.REQUEST_AUTHORISATION_GUIDANCE_TEXT,
      Entries.REQUEST_AUTHORISATION_DOCUMENTATION_TEXT,
      Entries.REQUEST_AUTHORISATION_LEAD_NETWORK_TEXT,
      Entries.REQUEST_AUTHORISATION_SUBMIT_TEXT,
    ],
  },
  [Screens.IS_FUNDER_AMRC_MEMBER_SCREEN]: {
    screenTagId: 'soecatIsFunderAmrcMemberScreen',
    entries: [Entries.IS_FUNDER_AMRC_MEMBER_GUIDANCE_TEXT],
  },
  [Screens.PER_PARTICIPANT_ADD_ACTIVITY_MODAL]: {
    screenTagId: 'soecatPerParticipantAddActivityModal',
    entries: [Entries.PER_PARTICIPANT_ADD_ACTIVITY_GUIDANCE_TEXT],
  },
  [Screens.TRIAGE_SCREEN]: {
    screenTagId: 'soecatTriageScreen',
    entries: [Entries.TRIAGE_GUIDANCE_TEXT],
  },
  [Screens.MANAGE_SOECAT_SCREEN]: {
    screenTagId: 'manageSoecatScreen',
    entries: [
      Entries.MANAGE_SOECAT_PENDING_VALIDATION_BANNER,
      Entries.MANAGE_SOECAT_AUTHORISED_STUDY_REP_BANNER,
      Entries.MANAGE_SOECAT_COMPLETED_PENDING_COMMISSIONING_BANNER,
      Entries.MANAGE_SOECAT_REVERTED_STUDY_REP_BANNER,
      Entries.MANAGE_SOECAT_REVERTED_ACCORD_SPECIALIST_BANNER,
      Entries.MANAGE_SOECAT_AUTHORISED_ACCORD_SPECIALIST_BANNER,
    ],
  },
}

export default screenNameMapping
