import { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { api } from '@cpms/common/helpers/api-axios'
import { ErrorProps } from '@cpms/common/components/ErrorMessage'
import Card from '@cpms/common/components/Card'
import Loader from '@cpms/common/components/Loader'
import { log } from '@cpms/common/helpers/logger'
import { ErrorSummaryProvider } from '@cpms/common/context/ErrorSummaryContext'
import { queryOptions } from '@cpms/common/config/settings'

import {
  ManageSoecat,
  IsApplyingForFunding,
  ApplyingForPortfolioSupport,
  FunderList,
  HasSecuredFunding,
  IsNhsStudy,
  Summary,
  WillStudyBeSingleSite,
} from '@app/pages/SoecatWorkflow/index'
import { QueryKeys } from '@app/models/QueryKeys.enum'

import { CamundaClient } from '../clients/camundaClient'
import { environmentVariables } from '../environment-variables'
import { SoecatProperties } from '../models/SoecatProperties.interface'
import { initialErrors } from '../config/settings'
import IsFunderAmrcMember from './SoecatWorkflow/IsFunderAmrcMember'

type SoecatWorkflowParams = {
  soecatId: string
}

const SoecatWorkflow = () => {
  const constants = {
    CAMUNDA_TASK_APPLYING_FOR_FUNDING: 'UserActivity - Are you applying for funding',
    CAMUNDA_TASK_SECURED_FUNDING: 'UserActivity - Have you Secured Funding',
    CAMUNDA_TASK_FUNDER_IS_IN_LIST: 'UserActivity - Funder is in the List',
    CAMUNDA_TASK_APPLYING_FOR_PORTFOLIO_SUPPORT: 'User Activity - Are you applying for portfolio support',
    CAMUNDA_TASK_IS_NHS_STUDY: 'User Activity - Will your study take place inside the NHS',
    CAMUNDA_TASK_SINGLE_SITE: 'User Activity - Will your study be single site',
    CAMUNDA_TASK_PORTFOLIO_SOECAT: 'UserActivity - Create a Portfolio SoECAT',
    CAMUNDA_TASK_NO_SOECAT: 'User Activity - No SoECAT required',
    CAMUNDA_TASK_IRAS_SOECAT: 'User Activity - Create an IRAS SoECAT',
    CAMUNDA_TASK_MANAGE_SOECAT: 'User Activity - Manage SoECAT',
    CAMUNDA_TASK_IS_FUNDER_AMRC_MEMBER: 'User Activity - Is Funder an AMRC Member',
    CAMUNDA_PROCESS_SOECAT_WORKFLOW: 'Process_SoecatWorkflow',
  }

  const [currentTask, setCurrentTask] = useState<string>()
  const [soecatProperties, setSoecatProperties] = useState<SoecatProperties>()
  const { soecatId } = useParams<SoecatWorkflowParams>()
  const [errors, setErrors] = useState<ErrorProps>({ ...initialErrors })
  const [isPageRefresh, setIsPageRefresh] = useState<boolean>(false)

  const isMounted = useRef(true) // Hacky fix for state updates occuring on unmount

  const setHookForRefresh = () => {
    setIsPageRefresh(!isPageRefresh)
  }

  const catchServerErrors = (label: string, message: string) => {
    setErrors({
      ...errors,
      error: true,
      label,
      message,
    })
  }

  useQuery(
    [QueryKeys.GetSoecatProperties, isPageRefresh],
    () => api.get<SoecatProperties>(`${environmentVariables.REACT_APP_SOECAT_API_URL}/properties/${soecatId}`),
    {
      ...queryOptions,
      onSuccess: ({ data }) => {
        const { businessKey, soecatTypeId, ...rest } = data
        if (businessKey) {
          setSoecatProperties({ ...rest, businessKey, soecatTypeId })
          CamundaClient.getCurrentTask(businessKey)
            .then((camundaResponse) => {
              log.debug('camunda getTaskByBusinessKey response: ', camundaResponse)
              if (isMounted.current) {
                setCurrentTask(camundaResponse.name)
                setErrors({ ...errors, error: false })
              }
            })
            .catch((e) => {
              console.log('server error: ', e)
              catchServerErrors('server error', 'Encounter error while connecting to server')
            })
        } else {
          CamundaClient.startProcess({
            input: {
              processName: constants.CAMUNDA_PROCESS_SOECAT_WORKFLOW,
            },
          })
            .then((camundaResponse) => {
              if (isMounted.current) {
                setCurrentTask(camundaResponse.name)
                api
                  .put<SoecatProperties>(`${environmentVariables.REACT_APP_SOECAT_API_URL}/properties/put`, {
                    soecatId,
                    businessKey: camundaResponse.businessKey,
                  })
                  .then((serverResponse) => {
                    setSoecatProperties({
                      ...serverResponse.data,
                      soecatTypeId: serverResponse.data.soecatTypeId,
                    })
                  })
                  .catch((e) => {
                    console.log('server error: ', e)
                  })
              }
            })
            .catch((e) => {
              console.log('camunda server error: ', e)
              catchServerErrors('camunda server error', 'Encounter error while connecting to server')
            })
        }
      },
    },
  )

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  const taskMapper = () => {
    switch (currentTask) {
      case constants.CAMUNDA_TASK_APPLYING_FOR_FUNDING:
        return <IsApplyingForFunding soecatProperties={soecatProperties} setHookForRefresh={setHookForRefresh} />
      case constants.CAMUNDA_TASK_SECURED_FUNDING:
        return <HasSecuredFunding soecatProperties={soecatProperties} setHookForRefresh={setHookForRefresh} />
      case constants.CAMUNDA_TASK_FUNDER_IS_IN_LIST:
        return (
          <ErrorSummaryProvider>
            <FunderList soecatProperties={soecatProperties} setHookForRefresh={setHookForRefresh} />
          </ErrorSummaryProvider>
        )
      case constants.CAMUNDA_TASK_IS_FUNDER_AMRC_MEMBER:
        return <IsFunderAmrcMember soecatProperties={soecatProperties} setHookForRefresh={setHookForRefresh} />
      case constants.CAMUNDA_TASK_APPLYING_FOR_PORTFOLIO_SUPPORT:
        return <ApplyingForPortfolioSupport soecatProperties={soecatProperties} setHookForRefresh={setHookForRefresh} />
      case constants.CAMUNDA_TASK_IS_NHS_STUDY:
        return <IsNhsStudy soecatProperties={soecatProperties} setHookForRefresh={setHookForRefresh} />
      case constants.CAMUNDA_TASK_SINGLE_SITE:
        return <WillStudyBeSingleSite soecatProperties={soecatProperties} setHookForRefresh={setHookForRefresh} />
      case constants.CAMUNDA_TASK_PORTFOLIO_SOECAT:
      case constants.CAMUNDA_TASK_IRAS_SOECAT:
      case constants.CAMUNDA_TASK_NO_SOECAT:
        return (
          <Summary
            currentTask={currentTask}
            soecatProperties={soecatProperties}
            setHookForRefresh={setHookForRefresh}
          />
        )
      default:
        if (soecatProperties?.soecatTypeId != null) {
          return (<ManageSoecat
            currentTask={currentTask}
            soecatProperties={soecatProperties}
            setHookForRefresh={setHookForRefresh}
           />)
        } else {
          return (<Card>
            <Loader message='Loading...' />
          </Card>)
        }     
    }
  }

  return (
    <>
      {errors.error && <div className='err'>Error Loading Page</div>}

      {!soecatProperties ? (
        <Card>
          <Loader message='Loading...' />
        </Card>
      ) : (
        taskMapper()
      )}
    </>
  )
}

export default SoecatWorkflow
