import React from 'react'
import ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { LiveAnnouncer } from 'react-aria-live'
import AuthProvider from '@cpms/common/context/AuthProvider'
import { log } from '@cpms/common/helpers/logger'

import App from './App'
import './index.css'

import reportWebVitals from './reportWebVitals'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import { Provider } from './context/Provider'

const queryClient = new QueryClient()

log.disableAll()

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Provider>
          <LiveAnnouncer>
            <App />
          </LiveAnnouncer>
        </Provider>
      </AuthProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
