import React, { Fragment, Suspense, useEffect, useRef } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import ContentLoader from 'react-content-loader'
import cx from 'classnames'
import log from 'loglevel'
import { useKey } from 'react-use'

import { MenuItem } from '../../models/MenuResponse.interface'

import './Navigation.styles.scss'

type NavigationProps = {
  isOpen: boolean
  fetchingMenu?: boolean
  menuItems?: Array<MenuItem>
  toggleMenu: React.Dispatch<React.SetStateAction<boolean>>
  toggleRef: React.RefObject<HTMLButtonElement>
  titleRef: React.RefObject<HTMLAnchorElement>
  routeChangeFocusRef: React.RefObject<HTMLSpanElement>
}

const styles = {
  nav: 'overflow-hidden absolute z-20 bottom-0 h-full bg-white shadow-navigation transition-width duration-200 ease-in-out motion-reduce:transition-none',
  list: 'transition-all duration-200 ease-in-out',
  link: 'text-sm items-center py-3 px-4',
  linkText:
    'ml-5 whitespace-nowrap transform -translate-x-3 opacity-0 transition duration-200 ease-in-out motion-reduce:transform-none motion-reduce:transition-none motion-reduce:opacity-100',
  hr: 'border-gray-light my-4',
}

export const Navigation = ({
  isOpen,
  fetchingMenu,
  menuItems,
  toggleMenu,
  toggleRef,
  titleRef,
  routeChangeFocusRef,
}: NavigationProps) => {
  const { pathname } = useLocation()
  const navRefs = useRef<Array<HTMLAnchorElement | null>>([])

  useEffect(() => {
    /* SPA A11Y Focus Hack https://github.com/remix-run/react-router/issues/5210#issuecomment-836418102 */
    routeChangeFocusRef.current?.focus()
    return () => toggleMenu(false) /* Close menu on unmount of route */
  }, [pathname])

  useKey(
    'Tab',
    (evt) => {
      if (isOpen) {
        const focusedIdx = document.activeElement?.getAttribute('data-order')
        if (evt.shiftKey && focusedIdx === '0') {
          evt.preventDefault()
          toggleRef.current?.focus()
          toggleMenu(false)
        }
        if (!evt.shiftKey && Number(focusedIdx) === navRefs.current.length - 1) {
          evt.preventDefault()
          titleRef.current?.focus()
          toggleMenu(false)
        }
      }
    },
    {},
    [isOpen],
  )

  const navRef = useRef(null)

  return (
    <nav
      id='cpms-nav'
      ref={navRef}
      role='navigation'
      aria-label='Main application links'
      data-testid='nav-bar'
      className={cx('navigation', styles.nav, {
        'navigation--expanded': isOpen,
      })}>
      <ul className={cx(styles.list, { 'p-3': isOpen })}>
        {fetchingMenu &&
          Array(5)
            .fill(null)
            .map((_, idx) => {
              return (
                <Fragment key={idx}>
                  <li className='px-4 py-3'>
                    <ContentLoader
                      speed={2}
                      width={21}
                      height={21}
                      viewBox='0 0 21 21'
                      backgroundColor='#f3f3f3'
                      foregroundColor='#ecebeb'>
                      <rect x='0' y='0' rx='2' ry='2' width='21' height='21' />
                    </ContentLoader>
                  </li>
                  {idx === 1 && <hr className={styles.hr} aria-hidden />}
                </Fragment>
              )
            })}

        {!fetchingMenu &&
          menuItems &&
          menuItems.map(({ itemPath, itemUrl, displayText, icon, newTab }, idx) => {
            const Icon = React.lazy(() =>
              import(`../Icons/Navigation/${icon}`)
                .then((comp) => ({ default: comp.default }))
                .catch((err) => {
                  log.error('Error lazy loading nav icon', err)
                  return { default: null }
                }),
            )

            const isActive = itemPath === pathname

            return (
              <Fragment key={itemUrl}>
                <li>
                  <a
                    href={itemUrl}
                    className={cx('navigation-link', styles.link, {
                      flex: isOpen,
                      'inline-flex': !isOpen,
                    })}
                    aria-label={`${displayText}${newTab ? ' (opens in new window)' : ''}`}
                    target={newTab ? '_blank' : ''}
                    tabIndex={isOpen ? 2 : 0}
                    data-order={idx}
                    ref={(el) => {
                      navRefs.current[idx] = el
                    }}
                    onClick={() => {
                      if (isActive) window.location.reload()
                    }}>
                    <Suspense fallback={null}>
                      <div>
                        <Icon aria-hidden width={21} height={21} className='fill-current' />
                      </div>
                    </Suspense>
                    <span className={cx('navigation-link-text', styles.linkText)}>{displayText}</span>
                  </a>
                </li>
                {idx === 1 && (
                  <li className='m-0' aria-hidden>
                    <hr className={styles.hr} />
                  </li>
                )}
              </Fragment>
            )
          })}
      </ul>
    </nav>
  )
}

export default Navigation
