import { forwardRef } from 'react'
import cx from 'classnames'
import { InputInterface } from '../../custom'
import './drop-down.css'
import VisuallyHidden from '../VisuallyHidden/VisuallyHidden'

const DropDown = forwardRef<any, InputInterface & { labelClassName?: string; defaultOption?: string | null }>(
  (
    {
      id,
      type = 'text',
      placeholder = 'Select option',
      value = '',
      setValue,
      required = true,
      error,
      label = '',
      description = '',
      options = [],
      defaultOption = 'Select Option',
      tabIndex = 0,
      'data-testid': dataTest,
      selected = '',
      disabled = false,
      className,
      labelClassName,
      parentDivClassName = '',
      readOnly,
      ...rest
    },
    ref,
  ) => {
    let legacyProps = null
    if (setValue) {
      legacyProps = {
        name: 'nation', // This was previously hardcoded to 'nation'. @todo fix consumers of this to enforce name on the component level
        onChange: (evt: React.ChangeEvent<HTMLSelectElement>) => setValue(evt.target.value),
      }
    }
    return (
      <div className={parentDivClassName}>
        <label htmlFor={id} className={labelClassName}>
          {label} {readOnly && <VisuallyHidden>(read only)</VisuallyHidden>}
        </label>
        {error && (
          <div id={`${id || ''}-error`} className='err text-base'>
            {error || 'Invalid selection'}
          </div>
        )}
        {description && <p className='description'>{description}</p>}
        <select
          {...rest}
          {...legacyProps}
          id={id}
          placeholder='Please select'
          className={cx(`nhsuk-select drop-down arrows text-base`, className, {
            error,
            'read-only': readOnly,
          })}
          tabIndex={tabIndex}
          data-testid={dataTest}
          required={readOnly ? false : required}
          ref={ref}
          value={value}
          disabled={disabled}
          aria-describedby={error && id ? `${id}-error` : undefined}
        >
          {options.length ? (
            <>
              {defaultOption !== null && (
                <option value='0' disabled>
                  {defaultOption}
                </option>
              )}
              {options.map((option, i) => (
                <option
                  key={i}
                  value={option.id}
                  disabled={(!option.id && option.id !== 0) || readOnly || option.disabled}
                >
                  {option.name || option.description}
                </option>
              ))}
            </>
          ) : (
            <option value=''>Loading...</option>
          )}
        </select>
      </div>
    )
  },
)

export default DropDown
