type VisuallyHiddenProps = {
  as?: keyof JSX.IntrinsicElements
  children: React.ReactNode
}

export const VisuallyHidden = ({ as = 'span', children }: VisuallyHiddenProps) => {
  const Tag = as
  return <Tag className='sr-only'>{children}</Tag>
}

export default VisuallyHidden
