import cx from 'classnames'
import Button from '../Button'
import Loader from '../Loader/Loader'
import { useSession } from '../../context/AuthProvider'
import isAcordSpecialist from '../PermissionToView/permissionHelpers/isAcordSpecialist'
import isTriageOfficer from '../PermissionToView/permissionHelpers/isTriageOfficer'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

import './bottom-controller.css'

type BottomControllerProps = {
  children?: React.ReactNode
  className?: string
  returnUrl?: string
  redirectUrl?: string
  progress?: boolean
  message?: string
  cancelButton?: string
  continueButton?: string
  showContinueButton?: boolean
}

const BottomController = ({
  children,
  className,
  returnUrl = '/my-service-applications',
  progress = false,
  redirectUrl = '',
  message = 'Loading...',
  cancelButton = 'Cancel application',
  continueButton = 'Continue',
  showContinueButton = true,
}: BottomControllerProps) => {
  const [{ user }] = useSession()
  const { search } = useLocation()
  const pathParameters = queryString.parse(search).pp !== undefined ? queryString.parse(search).pp : 'msa'
  let cancelUrl = redirectUrl ? `${redirectUrl}${returnUrl}` : returnUrl
  if ((isAcordSpecialist(user) || isTriageOfficer(user)) && pathParameters === 'sd') cancelUrl = '/my-soecats'

  return (
    <div
      className={cx('bottom-card mt-3 flex py-5 px-6', className, {
        'justify-between': showContinueButton,
        'justify-end': !showContinueButton,
      })}
    >
      {children}

      {showContinueButton && (
        <Button disabled={progress} variant='primary' text={continueButton} data-testid='continueButton' />
      )}

      {progress && <Loader message={message} />}

      <a className='nhsuk-button nhsuk-button--secondary mb-0' href={cancelUrl} data-testid='cancelApplicationButton'>
        {cancelButton}
      </a>
    </div>
  )
}

export default BottomController
