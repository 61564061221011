import log from 'loglevel'
import prefix from 'loglevel-plugin-prefix'

Object.defineProperty(global, 'log', {
  value: log,
  writable: false,
})

prefix.reg(log)
log.enableAll()

prefix.apply(log, {
  template: '[%t] %l:',
  levelFormatter(level) {
    return level.toUpperCase()
  },
  timestampFormatter(date) {
    return date.toISOString()
  },
})

// if (process.env.DEBUG_LOG_LEVEL === 0) {
//   log.disableAll()
// }

export { default as log } from 'loglevel'
