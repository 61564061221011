export enum QueryKeys {
  GetSoecatProperties = 'GetSoecatProperties',
  GetSoecatDashboard = 'GetSoecatDashboard',
  GetSoecatStudy = 'GetSoecatStudy',
  GetSoecatSets = 'GetSoecatSets',
  GetSoecatSet = 'GetSoecatSet',
  GetSoecatStatus = 'GetSoecatStatus',
  GetActivityVisits = 'GetActivityVisits',
  GetCostAttributionsForSoecat = 'GetCostAttributionsForSoecat',
  GetSoecatComments = 'GetSoecatComments',
  GetSoecatStudyRepresentatives = 'GetSoecatStudyRepresentatives',
  GetSoecatsForCopy = 'GetSoecatsForCopy',

  // Summary
  GetPerParticipantCosts = 'GetPerParticipantCosts',
  GetParticipantTotalCosts = 'GetParticipantTotalCosts',
  GetGeneralActivitiesCosts = 'GetGeneralActivitiesCosts',
  GetTotalSiteCosts = 'GetTotalSiteCosts',

  // SysRef
  SysRefLeadNetworks = 'SysRefLeadNetworks',
  SysRefSoecatDocumentType = 'SysRefSoecatDocumentType',

  // Auth
  GetStudyAuthorisation = 'GetStudyAuthorisation',

  // Documents
  GetSoecatAttachments = 'GetSoecatAttachments',
  GetPresignedUrlForDownload = 'GetPresignedUrlForDownload',

  // Costs
  GetCompleteEtc = 'GetCompleteEtc',
}
