type HomeProps = { width?: number; height?: number; className?: string }

export default function Home({ width = 20, height = 17, className = 'fill-current', ...props }: HomeProps) {
  return (
    <svg
      className={`nhsuk-icon ${className}`}
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='17'
      viewBox='0 0 20 17'
      style={{ width, height }}
      aria-hidden
      {...props}
    >
      <g fillRule='evenodd' stroke='none' strokeWidth='1'>
        <g fillRule='nonzero' transform='translate(-22 -88)'>
          <g transform='translate(20 85)'>
            <path d='M10 20L10 14 14 14 14 20 19 20 19 12 22 12 12 3 2 12 5 12 5 20z'></path>
          </g>
        </g>
      </g>
    </svg>
  )
}
