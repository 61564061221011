export interface Auth {
  sub: string
  email: string
  accessToken: string
  refreshToken: string
}

export interface User {
  FirstName: string
  LastName: string
  Email: string
  UserId: string
  PersonId: number
  StudyId: number
  StudyRoles: string[]
  Roles: string[]
}

export type AuthError = string | undefined

export interface Session {
  auth: Auth
  user: User
  error: AuthError
}

export enum Roles {
  PublicUser = 'PublicUser',
  System = 'System',
  TriageOfficer = 'Triage Officer',
  AcoRDSpecialist = 'AcoRD Specialist',
  CompanyRepresentative = 'CompanyRepresentative',
  ICTViewer = 'ICTViewer',
  StudyResourceReviewer = 'StudyResourceReviewer',
  SiteRepresentative = 'SiteRepresentative',
  StudyRepresentativeAndAccordSpecialist = 'StudyRepresentativeAndAccordSpecialist',
  // @todo: add more when needed
}

// Database stores these in an inconsistent way
export enum StudyRoles {
  Unknown = 'Unknown',
  ResearchActivityCoordinator = 'Research Activity Co-Ordinator',
  StudyCoordinator = 'Study Co-Ordinator',
  ChiefInvestigator = 'Chief Investigator',
  CompanyRepresentative = 'Company Representative',
  StudyResourceReviewer = 'Study Resource Reviewer',
  SiteRepresentative = 'Site Representative',
  NonCommStudyUser = 'NonCommStudyUser',
  StudyRepresentative = 'Study Representative',
}
