import React, { forwardRef } from 'react'
import cx from 'classnames'
import { InputInterface } from '../../custom'
import RichText from '../RichText'
import './input.scss'
import VisuallyHidden from '../VisuallyHidden/VisuallyHidden'

const Input = forwardRef<any, InputInterface & { verticalSpacing?: boolean }>(
  (
    {
      id,
      type = 'text',
      placeholder = '',
      value = '',
      setValue,
      required = true,
      error = '',
      maxLength,
      minLength = 0,
      tabIndex = 0,
      description = '',
      disabled = false,
      'data-testid': dataTest = '',
      textarea,
      resize,
      autoComplete = 'both',
      rightIcon,
      centreIcon,
      className,
      wrapperClassName,
      showCurrency,
      label = '',
      showSpinButton,
      min,
      max,
      step,
      verticalSpacing = true,
      readOnly = false,
      ...rest
    },
    ref,
  ) => {
    const isLegacy = !!setValue // DON'T use `setValue`. This indicates older & deprecated use of forms in this project. Use <Field> from Formik library

    let legacyProps = null
    if (isLegacy) {
      legacyProps = {
        onChange: (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
          setValue && setValue(evt.target.value),
      }
    }

    const extraProps: Record<string, unknown> = {}
    if (showCurrency) extraProps.step = 'any'

    const props = {
      ...rest,
      ...legacyProps,
      ...extraProps,
      id,
      className: cx(className, 'input text-base', {
        'nhsuk-textarea': textarea,
        'nhsuk-textarea--no-resize': textarea && resize === 'none',
        'nhsuk-input nhsuk-input--width-20': !textarea,
        'nhs-input--error error': error,
        'input--search': !!rightIcon || !!centreIcon,
        'input--currency': showCurrency,
        'input--spin-button': showSpinButton,
      }),
      placeholder,
      value,
      required: readOnly ? false : required,
      maxLength,
      minLength,
      resize,
      autoComplete,
      tabIndex,
      disabled,
      'aria-describedby': (error || description) && id ? `${id}-error description-${id}` : undefined,
      'aria-disabled': disabled,
      'data-testid': dataTest,
      ref,
      min,
      max,
      step,
      readOnly,
      // Prevent 'e' and 'E' characters being inputted into number inputs. Browsers allow this by default.
      ...(type === 'number' && {
        onKeyDown: (evt: React.KeyboardEvent) => {
          if (evt.key === 'e' || evt.key === 'E') {
            evt.preventDefault()
          }
        },
      }),
    }

    return (
      <div
        className={cx('input-card', wrapperClassName, {
          'input-card--v-spacing': verticalSpacing,
        })}>
        <label htmlFor={id} data-testid={`${dataTest}-label`}>
          {label} {readOnly && <VisuallyHidden>(read only)</VisuallyHidden>}
        </label>
        {error && (
          <span id={`${id || ''}-error`} className='err text-base'>
            {error}
          </span>
        )}

        {description && typeof description === 'string' ? (
          <p id={`description-${id}`} className='description' data-testid={`${dataTest}-description`}>
            {description}
          </p>
        ) : Object.keys(description).length !== 0 ? (
          <RichText
            id={`description-${id}`}
            className='description'
            data-testid={`${dataTest}-description`}
            // @ts-ignore
            richTextDocument={description}
          />
        ) : (
          <></>
        )}
        <div className={centreIcon ? 'has-centre-icon relative' : 'relative'}>
          {rightIcon && <div className='right-icon'>{rightIcon}</div>}

          {textarea ? (
            <textarea {...props} />
          ) : (
            <>
              {showCurrency && <div className='currency text-base'>&pound;</div>}
              <input type={type} {...props} />
            </>
          )}
        </div>
      </div>
    )
  },
)

export default Input
