import React from 'react'
import type { ValidationError } from '../custom'

export type FieldError = {
  label: string
  formInputId?: string
  description?: string
  route?: React.FC<{ innerRef?: React.Ref<HTMLAnchorElement> }>
}
type Payload = { message: string; errors: Array<FieldError> }
type Action =
  | { type: 'add'; payload: Payload }
  | { type: 'clear' }
  | { type: 'focus'; payload?: boolean }
  | { type: 'unrecoverable' }
type Dispatch = (action: Action) => void
export type ErrorSummaryContextState = {
  error: boolean
  message: string | undefined
  multipleErrors: Array<ValidationError>
  focus?: boolean
}
type ErrorSummaryProviderProps = { children: React.ReactNode }

const ErrorSummaryContext = React.createContext<{ state: ErrorSummaryContextState; dispatch: Dispatch } | undefined>(
  undefined,
)

function errorMessageReducer(state: ErrorSummaryContextState, action: Action) {
  switch (action.type) {
    case 'add': {
      return {
        ...state,
        error: true,
        message: action.payload.message,
        multipleErrors: action.payload.errors.map((error) => ({
          error: true,
          name: '', // why is this needed in the ValidationError interface?
          label: error.label,
          formInputId: error.formInputId,
          description: error.description,
          route: error?.route,
        })),
      }
    }
    case 'clear': {
      return {
        ...state,
        error: false,
        message: undefined,
        multipleErrors: [],
      }
    }
    case 'focus': {
      return {
        ...state,
        focus: action.payload ?? true,
      }
    }
    case 'unrecoverable': {
      return {
        ...state,
        error: true,
        focus: true,
        message: 'Unable to complete the request, please refresh the page and try again',
        multipleErrors: [
          {
            error: true,
            label: 'Refresh page',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            route: ({ innerRef }: any) => {
              return (
                <button
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  ref={innerRef}
                  onClick={() => global.window.location.reload()}
                  className='nhsuk-button--link text-base underline text-blue'
                >
                  Refresh page
                </button>
              )
            },
          },
        ],
      }
    }
    default: {
      throw new Error(`Unhandled action type`)
    }
  }
}

export function ErrorSummaryProvider({ children }: ErrorSummaryProviderProps) {
  const [state, dispatch] = React.useReducer(errorMessageReducer, {
    error: false,
    message: '',
    multipleErrors: [],
    focus: false,
  })
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = { state, dispatch }
  return <ErrorSummaryContext.Provider value={value}>{children}</ErrorSummaryContext.Provider>
}

export function useErrorSummary() {
  const context = React.useContext(ErrorSummaryContext)
  if (context === undefined) {
    throw new Error('useErrorSummary must be used within a ErrorProvider')
  }
  return context
}
