import React from 'react'
import { Menu, MenuList, MenuButton, MenuLink } from '@reach/menu-button'
import cx from 'classnames'
import '@reach/menu-button/styles.css'

import './DropdownMenu.styles.scss'

export interface DropdownMenuProps {
  items?: Item[]
  buttonText?: React.ReactNode
  buttonArrow?: boolean
  buttonClassName?: string
  buttonCustomElement?: React.FC
  className?: string
  'data-testid'?: string
}

export interface Item {
  as?: 'a' | 'button'
  text: string
  href?: string
  rel?: string
  target?: string
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
}

const DropdownMenu = ({
  items = [],
  className,
  'data-testid': dataTest = 'dropdown-menu',
  buttonText = 'Actions',
  buttonArrow = true,
  buttonClassName,
  buttonCustomElement,
}: DropdownMenuProps) => {
  return (
    <Menu className={cx('text-base', className)} data-testid={dataTest}>
      {buttonCustomElement ? (
        buttonCustomElement({ children: null }, 'hi')
      ) : (
        <MenuButton className={cx(buttonClassName)}>
          {buttonText} {buttonArrow && <span aria-hidden className='action-arrow icon icon-chevron-down ml-1 mt-1' />}
        </MenuButton>
      )}
      <MenuList role='listbox' className='rounded-sm border border-gray-400 py-0 shadow-md'>
        {items.map(({ text, as = 'a', href, rel, target = '', onClick }) => (
          <MenuLink
            role='option'
            as={as}
            href={href}
            rel={rel}
            target={target}
            key={text}
            className='font-base hover:text-blue-dark px-5 py-3 text-base hover:bg-transparent hover:underline'
            onClick={onClick}
            aria-label={target === '_blank' ? `${text} (opens in new window)` : undefined}
          >
            {text}
          </MenuLink>
        ))}
      </MenuList>
    </Menu>
  )
}

export default DropdownMenu
