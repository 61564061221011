import { createContext, useEffect, FC, useReducer, ReactNode } from 'react'

import { Action, State } from '@cpms/common/custom'
import { getParams } from '@app/helpers/misc/getParams'

const studyTypes = [
  {
    id: 0,
    name: '',
    description: '',
    sortOrder: 0,
  },
  {
    id: 1,
    name: 'Commercial',
    description: 'Commercial',
    sortOrder: 1,
  },
  { id: 2, name: 'Non-commercial', description: 'Non-commercial', sortOrder: 2 },
]

export type SoecatAppState = State

export const initialState: SoecatAppState = {
  studyId: 0,
  form: {
    studyTypes,
    studyId: 0,
    studyTypeId: 0,
    services: '',
    agreed: false,
    leadAdministrationId: 0,
    irasId: 0,
    title: '',
    shortTitle: '',
  },
  index: 0,
  error: false,
  message: '',
  dispatch: () => {},
}

const StoreContext = createContext<SoecatAppState>(initialState)
const storeReducer = (state: SoecatAppState, action: Action) => {
  switch (action.type) {
    case 'FORM':
      return { ...state, form: { ...state.form, ...action.payload } }

    case 'RESET':
      return { ...state, form: { ...state.form, ...action.payload } }

    case 'USER_DATA':
      return {
        ...state,
        studyId: action.payload.studyId,
        form: { ...state.form, ...action.payload.form },
      }

    case 'ERROR':
      return {
        ...state,
        message: action.payload.message,
        error: action.payload.error,
      }
    default:
      return {
        ...state,
        message: 'Unknown action',
        error: true,
      }
  }
}

const Provider: FC<{ initialState?: SoecatAppState; children: ReactNode }> = ({
  initialState: initialStateContext = initialState,
  children,
}) => {
  const [state, dispatch] = useReducer(storeReducer, initialStateContext)

  return (
    <StoreContext.Provider
      value={{
        form: state.form,
        studyId: state.studyId,
        index: state.index,
        error: state.error,
        message: state.message,
        dispatch,
      }}
      children={children}
    />
  )
}

export { Provider, StoreContext }
