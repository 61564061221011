import { ErrorProps } from '@cpms/common/components/ErrorMessage'

export const initialErrors: ErrorProps = {
  error: false,
  label: '',
  message: 'You must correct the following fields before you continue:',
  multipleErrors: [],
}

export enum StudyType {
  commercial = 1,
  nonCommercial = 2,
}

export const queryOptions = {
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  keepPreviousData: true,
}

export default {
  appTitle: 'CPMS – SoECAT',
  soecatsPerPage: 50,
  optionalTriageCommissioners: ['Specialised Commissioning', 'Not Applicable', 'Not Determined'],
}
