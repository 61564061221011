enum Screens {
  GUIDANCE_SCREEN,
  APPLYING_FOR_FUNDING_SCREEN,
  WHOS_IS_THE_FUNDER_GUIDANCE_SCREEN,
  APPLYING_FOR_PORTFOLIO_ADOPTION_GUIDANCE_SCREEN,
  HAS_SECURED_FUNDING_GUIDANCE_SCREEN,
  IS_STUDY_IN_NHS_GUIDANCE_SCREEN,
  WILL_STUDY_BE_SINGLE_SITE_GUIDANCE_SCREEN,
  GENERAL_ACTIVITIES_GUIDANCE_SCREEN,
  PERPARTICIPANT_ACTIVITIES_GUIDANCE_SCREEN,
  PERPARTICIPANT_ACTIVITIES_SET_GUIDANCE_SCREEN,
  ADD_ACTIVITY_MODAL,
  STANDARD_OF_CARE_GUIDANCE_SCREEN,
  STANDARD_OF_CARE_ADD_ACTIVITY_MODAL,
  REORDER_VISITS_MODAL,
  SUMMARY_SCREEN,
  REQUEST_AUTHORISATION_SCREEN,
  IS_FUNDER_AMRC_MEMBER_SCREEN,
  PER_PARTICIPANT_ADD_ACTIVITY_MODAL,
  TRIAGE_SCREEN,
  MANAGE_SOECAT_SCREEN,
}

export default Screens
