import React, { useEffect, useRef } from 'react'
import { initApi, setAuthToken } from '../helpers/api-axios'
import { Session } from '../models/Auth'
import AuthService from '../services/AuthService'
import { HttpStatusCode } from '../models/HttpStatusCode.enum'

type AuthContextState = Session
type AuthProviderProps = { children: React.ReactNode }
type Dispatch = (state: AuthContextState) => void

const AuthContext = React.createContext<[state: AuthContextState, dispatch: Dispatch] | undefined>(undefined)

const getSessionFromLocalStorage = () => {
  const sessionJsonString = localStorage.getItem('session')
  if (!sessionJsonString) return undefined
  return JSON.parse(sessionJsonString) as Session
}

export const getLogoutUrl = (accessToken: string) => {
  return `${process.env.REACT_APP_AUTH_SERVER_URL}/oidc/logout?id_token_hint=${accessToken}&post_logout_redirect_uri=${process.env.REACT_APP_AUTH_REDIRECT_URI}`
}

const initialState = {
  auth: {
    sub: '',
    email: '',
    accessToken: '',
    refreshToken: '',
  },
  user: {
    FirstName: '',
    LastName: '',
    Email: '',
    UserId: '',
    PersonId: 0,
    StudyId: 0,
    StudyRoles: [],
    Roles: [],
  },
  error: undefined,
}

export function AuthProvider({ children }: AuthProviderProps) {
  const [session, setSession] = React.useState<Session>(initialState)
  const error = useRef<string>()

  useEffect(() => {
    if (session.error === 'forbidden') {
      // @todo attempt a token refresh first
      AuthService.redirectToAuthorizationServer()
    }
  }, [session.error])

  useEffect(() => {
    const api = initApi((status: HttpStatusCode) => {
      if (status === HttpStatusCode.FORBIDDEN) error.current = 'forbidden'
      if (status === HttpStatusCode.UNAUTHORIZED) error.current = 'unauthenticated'
      setSession((state) => ({ ...state, error: error.current }))
    })

    const persistedSession = getSessionFromLocalStorage()
    const mappedSession = {
      auth: {
        sub: persistedSession?.auth.sub || '',
        email: persistedSession?.auth.email || '',
        accessToken: persistedSession?.auth.accessToken || '',
        refreshToken: persistedSession?.auth.refreshToken || '',
      },
      user: {
        FirstName: persistedSession?.user.FirstName || '',
        LastName: persistedSession?.user.LastName || '',
        Email: persistedSession?.user.Email || '',
        UserId: persistedSession?.user.UserId || '',
        PersonId: persistedSession?.user.PersonId || 0,
        StudyId: persistedSession?.user.StudyId || 0,
        StudyRoles: persistedSession?.user.StudyRoles || [],
        Roles: persistedSession?.user.Roles || [],
      },
      error: undefined,
    }
    if (persistedSession) {
      setSession((state) => ({ ...state, ...mappedSession, error: error.current }))
    }

    setAuthToken(api, persistedSession?.auth?.accessToken || '')

    AuthService.initialiseAuthFlow(
      mappedSession,
      (payload) => {
        setAuthToken(api, payload?.auth?.accessToken || '')
        setSession(payload)
        localStorage.setItem('session', JSON.stringify(payload))
      },
      () => {
        setSession((state) => ({ ...state, error: 'loginError' }))
      },
    )
  }, [])

  return <AuthContext.Provider value={[session, setSession]}>{children}</AuthContext.Provider>
}

export function useSession() {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useSession must be used within a AuthProvider')
  }
  return context
}

export default AuthProvider
