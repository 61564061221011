import { LiveMessage } from 'react-aria-live'
import { ErrorMessageWithFocus, ErrorProps } from '@cpms/common/components/ErrorMessage'
import { RadioButton, RadioGroup, Form } from '@cpms/common/components/Forms'
import { useRef, useState } from 'react'
import { api } from '@cpms/common/helpers/api-axios'
import './DecisionFlow.css'
import BottomController from '@cpms/common/components/BottomController'
import Card from '@cpms/common/components/Card'
import { Entries, Screens } from '@app/config'
import useContentful from '@app/hooks/useContentful'
import RichText from '@cpms/common/components/RichText'
import { initialErrors } from '../../config/settings'
import { CamundaRequest } from '../../models/CamundaRequest.interface'
import { CamundaClient } from '../../clients/camundaClient'
import { environmentVariables } from '../../environment-variables'

const IsFunderAmrcMember = ({ soecatProperties, setHookForRefresh }: any) => {
  const { items, isLoading } = useContentful(Screens.IS_FUNDER_AMRC_MEMBER_SCREEN)
  const constants = {
    title: 'Check your study requires a SoECAT',
    pageText:
      'To ensure that you are completing the correct SoECAT for your study, please answer the following question:',
    questionTitle: "You have selected 'my funder is not on the list'. Is your funder an AMRC member?",
    errorMessage: 'You must select an option for the following questions before you continue:',
  }
  const [inputValue, setInputValue] = useState<any>()
  // ref hooks
  const inputRef = useRef(null)
  const errorRef = useRef(null)
  // error handler
  const [errors, setErrors] = useState<ErrorProps>({ ...initialErrors })
  const [inProgress, setProgress] = useState<boolean>(false)

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    setProgress(true)
    e.preventDefault()
    if (inputValue != null) {
      setErrorsForValidation(false)
      api
        .put(`${environmentVariables.REACT_APP_SOECAT_API_URL}/properties/put`, {
          soecatId: soecatProperties.soecatId.toString(),
          isFunderAmrcMember: inputValue,
        })
        .then(() => {
          CamundaClient.completeTask({
            input: {
              businessKey: soecatProperties.businessKey,
              variables: {
                isFunderAmrcMember: { value: inputValue, type: 'Boolean' },
              },
            },
          } as CamundaRequest).then(() => {
            setHookForRefresh()
            setProgress(false)
          })
          return true
        })
    } else {
      setErrorsForValidation(true)
      setProgress(false)
      return false
    }
  }

  const setErrorsForValidation = (isError: boolean) => {
    setErrors({
      ...errors,
      message: constants.errorMessage,
      label: constants.questionTitle,
      error: isError,
      link: inputRef,
    })
  }

  const handleChange = (choice: any) => {
    setInputValue(choice)
  }

  return (
    <>
      <LiveMessage aria-live='assertive' message={`${constants.title} - ${constants.questionTitle}`} clearOnUnmount />

      <ErrorMessageWithFocus
        errors={{
          error: errors.error,
          message: errors.message,
          label: errors.label,
          description: '',
          link: errors.link,
        }}
      />

      <Form onSubmit={handleSubmit} onReset={() => null}>
        <Card>
          <h1 className='text-xl' data-testid='selectIsFunderAmrcMember'>
            {constants.title}
          </h1>

          <p className='bottomMargin'>{constants.pageText}</p>

          <RadioGroup id='questionText' label={constants.questionTitle}>
            {errors.error && <div className='err'>Invalid selection</div>}
            <div className='guidanceText'>
              {isLoading ? (
                <p>Loading...</p>
              ) : (
                <RichText
                  richTextDocument={items[Entries.IS_FUNDER_AMRC_MEMBER_GUIDANCE_TEXT]?.fields.richTextLinksOnly}
                />
              )}
            </div>
            <RadioButton
              label='Yes'
              description=''
              value={true}
              setValue={handleChange}
              error={errors.error}
              selected={inputValue}
              data-testid='yes'
              ref={inputRef}
            />

            <RadioButton
              label='No'
              description=''
              value={false}
              setValue={handleChange}
              error={errors.error}
              selected={inputValue}
              data-testid='no'
            />
          </RadioGroup>
        </Card>
        <BottomController
          progress={inProgress}
          redirectUrl={environmentVariables.REACT_APP_SIM_APP_BASE_URL}
          cancelButton='Return to dashboard'
        />
      </Form>
    </>
  )
}

export default IsFunderAmrcMember
