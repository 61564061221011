import { AxiosResponse } from 'axios'
import { api } from '@cpms/common/helpers/api-axios'
import { log } from '@cpms/common/helpers/logger'
import { CamundaResponse } from '../models/CamundaResponse.interface'
import { CamundaRequest } from '../models/CamundaRequest.interface'
import { environmentVariables } from '../environment-variables'

const config = {
  timeout: 15000,
}

const responseBody = (response: AxiosResponse<CamundaResponse>) => response.data

const requests = {
  startProcess: (url: string, body: CamundaRequest) => api.post<CamundaResponse>(url, body, config).then(responseBody),
  completeTask: (url: string, body: CamundaRequest) => api.post<CamundaResponse>(url, body, config).then(responseBody),
  completeProcess: (url: string, body: CamundaRequest) =>
    api.post<CamundaResponse>(url, body, config).then(responseBody),
  getCurrentTask: (url: string) => api.get<CamundaResponse>(url, config).then(responseBody),
}

export const CamundaClient = {
  startProcess: (post: CamundaRequest) =>
    requests.startProcess(`${environmentVariables.REACT_APP_BASE_URL}/camunda/startProcess`, post),
  completeTask: (post: CamundaRequest) =>
    requests.completeTask(`${environmentVariables.REACT_APP_BASE_URL}/camunda/completeTask`, post),
  completeProcess: (post: CamundaRequest) =>
    requests.completeProcess(`${environmentVariables.REACT_APP_BASE_URL}/camunda/completeProcess`, post),
  getCurrentTask: (businessKey: string) => {
    log.debug(
      'Fetching camunda getTaskByBusinessKey',
      `${environmentVariables.REACT_APP_BASE_URL}/camunda/getTaskByBusinessKey/${businessKey}`,
    )
    return requests.getCurrentTask(
      `${environmentVariables.REACT_APP_BASE_URL}/camunda/getTaskByBusinessKey/${businessKey}`,
    )
  },
}
