export const environmentVariables = {
  REACT_APP_CONTENTFUL_SPACE_ID: process.env.REACT_APP_CONTENTFUL_SPACE_ID || '',
  REACT_APP_CONTENTFUL_ACCESS_TOKEN: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN || '',
  REACT_APP_CONTENTFUL_ENVIRONMENT: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT || '',
  REACT_APP_CRN_REDIRECT: process.env.REACT_APP_CRN_REDIRECT || '',
  REACT_APP_SIM_APP_BASE_URL: process.env.REACT_APP_SIM_APP_BASE_URL || '',
  REACT_APP_BASE_URL: process.env.REACT_APP_BASE_API_URL || '',
  REACT_APP_SIM_API_URL: `${process.env.REACT_APP_BASE_API_URL || ''}/sim/v1`,
  REACT_APP_SOECAT_API_URL: `${process.env.REACT_APP_BASE_API_URL || ''}/soecat/v1`,
  REACT_APP_SOECAT_PROPERTIES_API_URL: `${process.env.REACT_APP_BASE_API_URL || ''}/soecat/v1/properties`,
  REACT_APP_SOECAT_REDIRECT: process.env.REACT_APP_SOECAT_REDIRECT || '',
  REACT_APP_CRN_APP_BASE_URL: process.env.REACT_APP_CRN_APP_BASE_URL || '',
}
