import './Footer.styles.scss'

const Footer = () => {
  return (
    <footer
      role='contentinfo'
      className='nhsuk-width-container  nhsuk-u-margin-top-5 '>
        <div className="flex justify-center nhsuk-u-margin-bottom-5">
          <div>
            Environment - <span id='current-environment'>{process.env.REACT_APP_ENVIRONMENT}</span>
          </div>
          <div className='nhsuk-u-margin-left-9 nhsuk-u-margin-right-9'>
            Version - <a id='current-version' href="https://sites.google.com/nihr.ac.uk/cpmsservicedefinition/cpms-development/cpms-releases-notes" target="_blank" aria-label="CPMS Application Release Notes (opens in a new tab)">
              {process.env.REACT_APP_VERSION}
              </a>
          </div>
          <div>
            <a href='https://docs.google.com/forms/d/e/1FAIpQLSdK-fQuJ3lmQdrdiOR8Lq9rUwuMOqWUmpB0HnU4Gr1_CM6ImQ/viewform' target='_blank' aria-label="CPMS Feedback form (opens in a new tab)">
              CPMS Feedback
            </a>
          </div>
      </div>
      <div className="flex justify-center nhsuk-u-margin-bottom-5">
      You can review our&nbsp;
        <a href="https://sites.google.com/nihr.ac.uk/rdncc-policies/central-portfolio-management-system/cpms-terms-and-conditions-of-use" aria-label="Terms and conditions (opens in a new tab)" target="_blank" >Terms and Conditions</a>
        ,&nbsp;
        <a href="https://sites.google.com/nihr.ac.uk/rdncc-policies/central-portfolio-management-system/cpms-privacy-notice" aria-label="Privacy Policy (opens in a new tab)" target="_blank" >Privacy Policy</a>
        &nbsp;and&nbsp;
        <a href="https://sites.google.com/nihr.ac.uk/rdncc-policies/central-portfolio-management-system/cpms-accessibility-statement" aria-label="Accessibility Statement (opens in a new tab)" target="_blank" >Accessibility Statement</a>
                  
      </div>
    </footer>
  )
}

export default Footer
