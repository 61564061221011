import React from 'react'
import cx from 'classnames'

import Heading from '../Heading'
import WarningIcon from '../Icons/Warning'
import TickIcon from '../Icons/Tick'
import InfoIcon from '../Icons/Info'

interface BannerProps extends React.HTMLAttributes<HTMLDivElement> {
  active: boolean
  type?: 'error' | 'success' | 'info' | 'message'
  heading?: React.ReactNode
  children?: React.ReactNode
  'data-testid'?: string
  innerRef?: React.ForwardedRef<HTMLDivElement>
  role?: 'alert' | undefined
}

export const Banner = ({
  active,
  type = 'success',
  heading,
  className,
  children,
  role = undefined, // Set `active` to true and `alert` to `role` for banners to be announced by screen readers
  'data-testid': dataTest = 'banner',
}: BannerProps) => {
  return (
    <div role={role}>
      {active && (
        <div
          className={cx('bg-pale-yellow my-3 flex items-start pt-3 pb-3', className, { 'px-3': type === 'message' })}
          data-testid={dataTest}
        >
          {type !== 'message' && (
            <div className='my-2 mx-3 flex w-12 items-center justify-center'>
              {type === 'error' && <WarningIcon />}
              {type === 'success' && <TickIcon />}
              {type === 'info' && <InfoIcon />}
            </div>
          )}
          <div className='m-1' data-testid={`${dataTest}-content`}>
            {heading && (
              <Heading
                as='h2'
                size='lg'
                className={cx('mb-1', {
                  'text-red': type === 'error',
                  'text-gray-dark': type === 'success',
                  'text-blue': type === 'info',
                })}
                data-testid={`${dataTest}-message`}
              >
                {heading}
              </Heading>
            )}
            {children}
          </div>
        </div>
      )}
    </div>
  )
}

export default Banner
