import cx from 'classnames'

import './Hamburger.styles.scss'

export const Hamburger = ({ isActive }: { isActive: boolean }) => {
  return (
    <div className={cx('hamburger transition-all duration-150 ease-in-out', { active: isActive })}>
      <div className='hamburger-box flex items-center'>
        <div className='hamburger-inner' />
      </div>
    </div>
  )
}

export default Hamburger
