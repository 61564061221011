import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { AuthError } from '../../models/Auth'
import AuthService from '../../services/AuthService'
import Loader from '../Loader'
import Card from '../Card'
import Banner from '../Banner/Banner'

type Reasons = AuthError

export const Error = () => {
  const { search } = useLocation()
  const { reason } = queryString.parse(search)

  useEffect(() => {
    if (reason === 'unauthenticated') {
      AuthService.clear()
    }
  }, [])

  const getErrorMessage = () => {
    switch (reason as Reasons) {
      case 'unauthenticated':
        return 'Unauthenticated'
      case 'notfound':
        return 'That page does not exist'
      default:
        break
    }
    return ''
  }

  if (reason === 'unauthenticated') {
    return (
      <Card>
        <Loader message='Logging In...'></Loader>
      </Card>
    )
  }

  return (
    <>
      <Banner active={true} type='error' heading={getErrorMessage()}>
        If you think you this is a mistake and the issue persists,{' '}
        <button
          className='nhsuk-button--link text-blue underline hover:no-underline'
          onClick={(evt: React.MouseEvent) => {
            evt.preventDefault()
            window.history.back()
          }}
        >
          try again
        </button>{' '}
        or{' '}
        <a href='https://portal.nihr.ac.uk/help?app=CPMS' rel='external'>
          contact support
        </a>
      </Banner>
    </>
  )
}

export default Error
