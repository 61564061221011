import cx from 'classnames'
import VisuallyHidden from '../VisuallyHidden/VisuallyHidden'
import './loader.css'

interface LoaderProps extends React.HTMLAttributes<HTMLDivElement> {
  message?: string
  size?: number
  className?: string
}

const Loader = ({ message = '', size, className, ...props }: LoaderProps) => {
  return (
    <div {...props} className={cx('loader-card', className)}>
      <div className='loader' style={{ width: size, height: size }}></div>
      {message && (
        <span className='loader-message text-base' aria-live='polite'>
          {message}
        </span>
      )}
    </div>
  )
}

export default Loader
