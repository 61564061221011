enum Entries {
  GUIDANCE_SCREEN_RICH_TEXT,
  APPLYING_FOR_FUNDING_GUIDANCE_TEXT,
  WHOS_IS_THE_FUNDER_GUIDANCE_TEXT,
  APPLYING_FOR_PORTFOLIO_ADOPTION_GUIDANCE_TEXT,
  HAS_SECURED_FUNDING_GUIDANCE_TEXT,
  IS_STUDY_IN_NHS_GUIDANCE_TEXT,
  WILL_STUDY_BE_SINGLE_SITE_GUIDANCE_TEXT,
  GENERAL_ACTIVITIES_GUIDANCE_TEXT,
  PERPARTICIPANT_ACTIVITIES_GUIDANCE_TEXT,
  PERPARTICIPANT_ACTIVITIES_SET_GUIDANCE_TEXT,
  ADD_ACTIVITY_GUIDANCE_TEXT,
  STANDARD_OF_CARE_GUIDANCE_TEXT,
  STANDARD_OF_CARE_ADD_ACTIVITY_GUIDANCE_TEXT,
  REORDER_VISITS_GUIDANCE_TEXT,
  SUMMARY_GUIDANCE_TEXT,
  REQUEST_AUTHORISATION_GUIDANCE_TEXT,
  REQUEST_AUTHORISATION_DOCUMENTATION_TEXT,
  REQUEST_AUTHORISATION_LEAD_NETWORK_TEXT,
  REQUEST_AUTHORISATION_SUBMIT_TEXT,
  IS_FUNDER_AMRC_MEMBER_GUIDANCE_TEXT,
  PER_PARTICIPANT_ADD_ACTIVITY_GUIDANCE_TEXT,
  TRIAGE_GUIDANCE_TEXT,
  MANAGE_SOECAT_PENDING_VALIDATION_BANNER,
  MANAGE_SOECAT_AUTHORISED_STUDY_REP_BANNER,
  MANAGE_SOECAT_COMPLETED_PENDING_COMMISSIONING_BANNER,
  MANAGE_SOECAT_REVERTED_STUDY_REP_BANNER,
  MANAGE_SOECAT_REVERTED_ACCORD_SPECIALIST_BANNER,
  MANAGE_SOECAT_AUTHORISED_ACCORD_SPECIALIST_BANNER,
}

export default Entries
