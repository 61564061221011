type WarningIconProps = { height?: number; width?: number; className?: string }

const WarningIcon = ({ height = 48, width = 56, className = 'fill-current', ...props }: WarningIconProps) => (
  <svg
    className={`nhsuk-icon nhsuk-icon__search ${className}`}
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 56 48'
    aria-hidden
    focusable='false'
    fill='#191919'
    style={{ width, height }}
    {...props}
  >
    <path fill='#E02020' d='M.5 47.5h55L28 0 .5 47.5zm30-7.5h-5v-5h5v5zm0-10h-5V20h5v10z' />
  </svg>
)

export default WarningIcon
