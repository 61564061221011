import React, { HTMLAttributes } from 'react'
import cx from 'classnames'

export interface HeadingProps {
  size: 'sm' | 'base' | 'lg' | 'xl'
  as: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  className?: string
  children?: React.ReactNode
}

const sizeClasses = {
  sm: 'text-sm',
  base: 'text-base',
  lg: 'text-lg',
  xl: 'text-xl',
}

const Heading = (props: HeadingProps & HTMLAttributes<HTMLHeadingElement>) => {
  const { as: HeadingTag = 'h1', size, children, className, ...extraProps } = props

  return (
    <HeadingTag className={cx(sizeClasses[size], className)} {...extraProps}>
      {children}
    </HeadingTag>
  )
}

export default Heading
