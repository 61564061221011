import Entries from './entries'

const screenNameMapping: any = {
  [Entries.GUIDANCE_SCREEN_RICH_TEXT]: 'soecatUqGuidanceText',
  [Entries.APPLYING_FOR_FUNDING_GUIDANCE_TEXT]: 'soecatApplyingForFundingGuidanceText',
  [Entries.WHOS_IS_THE_FUNDER_GUIDANCE_TEXT]: 'soecatWhoIsTheFunderGuidanceText',
  [Entries.APPLYING_FOR_PORTFOLIO_ADOPTION_GUIDANCE_TEXT]: 'soecatApplyingForPortfolioAdoptionGuidanceText',
  [Entries.HAS_SECURED_FUNDING_GUIDANCE_TEXT]: 'soecatHasSecuredFundingGuidanceText',
  [Entries.IS_STUDY_IN_NHS_GUIDANCE_TEXT]: 'soecatIsStudyInNHSGuidanceText',
  [Entries.WILL_STUDY_BE_SINGLE_SITE_GUIDANCE_TEXT]: 'soecatWillStudyBeSingleSiteGuidanceText',
  [Entries.GENERAL_ACTIVITIES_GUIDANCE_TEXT]: 'soecatGeneralActivitiesGuidanceText',
  [Entries.PERPARTICIPANT_ACTIVITIES_GUIDANCE_TEXT]: 'soecatPerParticipantActivitiesGuidanceText',
  [Entries.PERPARTICIPANT_ACTIVITIES_SET_GUIDANCE_TEXT]: 'soecatPerParticipantActivitiesSetGuidanceText',
  [Entries.ADD_ACTIVITY_GUIDANCE_TEXT]: 'soecatAddActivityModalGuidanceText',
  [Entries.STANDARD_OF_CARE_GUIDANCE_TEXT]: 'soecatStandardOfCareSetGuidanceScreen',
  [Entries.STANDARD_OF_CARE_ADD_ACTIVITY_GUIDANCE_TEXT]: 'soecatStandardOfCareAddActivityGuidanceText',
  [Entries.REORDER_VISITS_GUIDANCE_TEXT]: 'soecatReorderVisitsGuidanceText',
  [Entries.SUMMARY_GUIDANCE_TEXT]: 'soecatSummaryGuidanceText',
  [Entries.REQUEST_AUTHORISATION_GUIDANCE_TEXT]: 'soecatRequestAuthorisationGuidanceText',
  [Entries.REQUEST_AUTHORISATION_DOCUMENTATION_TEXT]: 'soecatRequestAuthorisationDocumentationText',
  [Entries.REQUEST_AUTHORISATION_LEAD_NETWORK_TEXT]: 'soecatRequestAuthorisationLeadNetworkText',
  [Entries.REQUEST_AUTHORISATION_SUBMIT_TEXT]: 'soecatRequestAuthorisationSubmitText',
  [Entries.IS_FUNDER_AMRC_MEMBER_GUIDANCE_TEXT]: 'soecatIsFunderAmrcMemberGuidanceText',
  [Entries.PER_PARTICIPANT_ADD_ACTIVITY_GUIDANCE_TEXT]: 'soecatPerParticipantAddActivityGuidanceText',
  [Entries.TRIAGE_GUIDANCE_TEXT]: 'soecatTriageGuidanceText',
  [Entries.MANAGE_SOECAT_PENDING_VALIDATION_BANNER]: 'manageSoecatPendingValidationSuccessBanner',
  [Entries.MANAGE_SOECAT_AUTHORISED_STUDY_REP_BANNER]: 'manageSoecatAuthorisedStudyRepresentativeSuccessBanner',
  [Entries.MANAGE_SOECAT_COMPLETED_PENDING_COMMISSIONING_BANNER]: 'manageSoecatCompletedOrCommissioningSuccessBanner',
  [Entries.MANAGE_SOECAT_REVERTED_STUDY_REP_BANNER]: 'manageSoecatRevertedStudyRepresentativeWarningBanner',
  [Entries.MANAGE_SOECAT_REVERTED_ACCORD_SPECIALIST_BANNER]: 'manageSoecatRevertedAccordSpecialistWarningBanner',
  [Entries.MANAGE_SOECAT_AUTHORISED_ACCORD_SPECIALIST_BANNER]: 'manageSoecatAuthorisedAccordSpecialistSuccessBanner',
}

export default screenNameMapping
