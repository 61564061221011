import React from 'react'
import { useErrorSummary } from '../../context/ErrorSummaryContext'

/**
 *
 * Custom <Form> wrapper component
 * Dispatches an action on every form submission event for auto focus of errors
 */

interface FormProps extends React.HTMLAttributes<HTMLFormElement> {
  children: React.ReactNode
  className?: string
  onSubmit: (evt: React.FormEvent<HTMLFormElement>) => void
  onReset: (evt: React.FormEvent<HTMLFormElement>) => void
}

export const Form = ({ children, className, onSubmit, onReset, ...rest }: FormProps) => {
  const errorMessages = useErrorSummary()

  return (
    <form
      noValidate
      onSubmit={(evt) => {
        errorMessages.dispatch({ type: 'focus' })
        onSubmit(evt)
      }}
      onReset={onReset}
      className={className}
      {...rest}
    >
      {children}
    </form>
  )
}

export default Form
